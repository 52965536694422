<template>
    <div>
        <form>
            <div class="form-group row mb-4 mt-4">
                <div class="col-xxl-5 col-xl-5 col-lg-6 col-md-12">
                    <label>Representative ID <span class="required-indc">*</span></label>
                    <div class="agent-input">
                        <input type="text" class="agent-field" placeholder="eg: NENY9999">
                        <span class="key-icon">
                            <img src="../../../assets/images/registration/key.svg" class="img-fluid">
                        </span>
                    </div>
                </div>
            </div>
        </form>
        <div class="action-btn  text-end pt-13">
            <router-link class="button-blue" to="/registration/group-info">Next</router-link>
        </div>
    </div>
</template>
<script>
export default {
    name: 'BrokerForm'
}
</script>
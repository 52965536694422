<template>
    <div class="registrationWrapper">
        <div class="container">
            <div class="whiteBG-wrap">
                <div class="row">                
                    <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-7">
                        <div class="regForm-wrap">
                            <h2>Rep Information</h2>
                            <hr>
                            <broker-form />
                        </div>
                    </div>
                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-5">
                        <broker-side-info />
                    </div>                    
                </div>
                <span class="procecss-title">Employer Registration</span>
            </div>
        </div>        
    </div>
</template>
<script>
import BrokerForm from '../../components/Registration/BrokerInfo/BrokerForm.vue'
import BrokerSideInfo from '../../components/Registration/BrokerInfo/BrokerSideInfo.vue'
export default {
    name: 'BrokerInfo',
    components: {
        BrokerForm,
        BrokerSideInfo
    }
}
</script>
<style src="../../assets/css/group-registration.css"></style>